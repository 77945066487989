import { Component } from '@zento-lib/components';

import { HeaderItem } from '../HeaderItem';

import style from './style.scss';

/**
 * Header Item Icon
 *
 * Header Item Icon allowing to create header items icons.
 **/
@Component({})
export class Icon extends HeaderItem {
  render() {
    return (
      <div class={style.blockItem} data-class={this.icon} data-testId={this.testId}>
        <span onClick={this.onItemPress}>
          <i class={{ [style[`icon-${this.icon}`]]: true }}></i>
          {this.count ? <span class={style.count}>{this.count.toString()}</span> : null}
          <slot />
        </span>
      </div>
    );
  }
}
